<template>
  <v-dialog
    v-model="DeleteDatasets"
    persistent
    width="475px"
    overlay-opacity="0.75"
  >
    <v-card tile class="pa-3">
      <v-card-title
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title v-if="$route.meta.request === 'articles'">Artikel löschen</v-toolbar-title>
              <v-toolbar-title v-if="$route.meta.request === 'appointments/table'">Termin löschen</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>

      <v-card-text class="text--primary py-5 px-3">
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="auto" v-if="$route.meta.request === 'articles'"
              >Möchtest du den Artikel / die Artikel (ggf. inklusive Historie) wirklich löschen? Dies kann nicht
              rückgängig gemacht werden!
            </v-col>
            <v-col cols="auto" v-if="$route.meta.request === 'appointments/table'"
              >Möchtest du den Termin wirklich löschen? Dies kann nicht
              rückgängig gemacht werden!
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                outlined
                color="red lighten-1"
                @click="abortDeleteDataset()"
              >
                <span>Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="green lighten-1" @click="confirmDeleteDataset()">
                <v-icon left class="grey--text text--lighten-4"
                  >mdi-check
                </v-icon>
                <span class="grey--text text--lighten-4"
                  >Löschen</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    DeleteDatasets: false,
  }),

  methods: {
    open() {
      this.DeleteDatasets = true;
    },

    confirmDeleteDataset() {
      this.DeleteDatasets = false;
      this.$emit("confirmDeleteDatasets", true);
    },

    abortDeleteDataset() {
      this.DeleteDatasets = false;
      this.$emit("abortDeleteDatasets", true);
    },
  },
};
</script>
