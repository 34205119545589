<template>
  <v-form>
    <v-layout>
      <v-app-bar app height="124" class="align-start elevation-1">
        <v-col cols="12" class="py-0">
          <v-row dense class="align-center flex-nowrap">
            <v-col cols="auto" class="d-flex d-lg-none pa-0">
              <!--Platzhalter für mobile Menu-->
              <div style="width: 50px"></div>
            </v-col>
            <v-col cols="auto">
              <v-toolbar-title>Termin bearbeiten</v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" v-if="checkCriticalArticle && checkCriticalAccessoires && checkCriticalNotes">
              <v-btn outlined color="red lighten-1" @click="openDeleteDatasets">
                <v-icon>mdi-delete-forever</v-icon>
                <span class="ml-1 d-none d-lg-flex">Löschen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" v-else>
              <v-btn outlined color="red lighten-1" @click="$router.go(-1)" :disabled="removalHappened">
                <v-icon>mdi-cancel</v-icon>
                <span class="ml-1 d-none d-lg-flex">Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="!checkDifferences && !checkCriticalDifferences">
              <v-btn class="green lighten-1 grey--text text--lighten-4" @click="fertig()" :disabled="validInsert">
                <v-icon>mdi-check</v-icon>
                <span class="ml-1 d-none d-lg-flex">Fertig</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" v-else>
              <v-btn class="green lighten-1 grey--text text--lighten-4" @click="kundenSpeichern()" :disabled="validInsert">
                <v-icon>mdi-check</v-icon>
                <span class="ml-1 d-none d-lg-flex">Speichern</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="align-center" v-if="editableDataset.Bearbeiter_ID">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-select
                hide-details
                dense
                outlined
                required
                placeholder="Bearbeiter(*)"
                v-model="editableDataset.Bearbeiter_ID"
                :items="Bearbeiter"
                item-text="Vorname"
                item-value="Bearbeiter_ID"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-app-bar>
      <v-container fluid class="px-0 pt-0" v-if="!editableDataset.Bearbeiter_ID">
        <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
          <!-- Termin- und Kundendaten -->
          <v-row dense class="align-center justify-center pb-5">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense align="center">
                    <v-col cols="12">
                      <v-toolbar-title>
                        Wer bearbeitet den Termin?*
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="5" md="6" sm="6">
                      <span class="font-weight-bold">Bearbeiter(*)</span>
                      <v-select
                        hide-details
                        dense
                        outlined
                        required
                        placeholder="Bearbeiter(*)"
                        v-model="editableDataset.Bearbeiter_ID"
                        :items="Bearbeiter"
                        item-text="Vorname"
                        item-value="Bearbeiter_ID"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row dense align="center">
                    <v-col cols="12">
                      <v-alert border="left" colored-border type="error" elevation="2" class="mt-2 mb-0">
                        <span class="subtitle-2"
                          >Das Entfernen von Artikeln, Accessoires oder Notizen ist sofort wirksam und kann nur durch wieder hinzufügen & speichern
                          rückgängig gemacht werden!</span
                        >
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-col>
            </v-card>
          </v-row>
        </v-col>
      </v-container>
      <v-container fluid class="px-0 pt-0" v-else>
        <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
          <!-- Termin- und Kundendaten -->
          <v-row dense class="align-center justify-center pb-5">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense align="center">
                    <v-col cols="12">
                      <v-toolbar-title>
                        Termin / Kundendaten
                      </v-toolbar-title>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" xl="4" lg="5" md="6" sm="6">
                      <span class="font-weight-bold">Abteilung(*)</span>
                      <v-select
                        v-model="editableDataset.Abteilung_ID"
                        :items="Abteilungen"
                        hide-details
                        dense
                        outlined
                        clearable
                        required
                        placeholder="Abteilung"
                        item-text="Abteilung_Kurzform"
                        item-value="Abteilung_ID"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" xl="4" lg="5" md="6" sm="6">
                      <span class="font-weight-bold">Terminkategorie(*)</span>
                      <v-select
                        v-model="editableDataset.Terminkategorie_ID"
                        :items="Terminkategorien"
                        hide-details
                        dense
                        outlined
                        clearable
                        required
                        placeholder="Terminkategorie"
                        item-text="Terminkategorie"
                        item-value="Terminkategorie_ID"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-divider inset class="my-3"></v-divider>
                  <v-row dense align="center">
                    <v-spacer></v-spacer>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="6">
                      <v-row dense v-if="editableDataset.Datum">
                        <v-col cols="auto" class="mx-auto font-weight-bold">
                          <v-card shaped width="225">
                            <v-card-title :class="dateColor" style="height:64px;">
                              <v-row dense align="center" class="">
                                <v-col cols="auto">
                                  <span>{{ getDateMonthName(editableDataset.Datum) }}</span>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                  <span>{{ new Date(editableDataset.Datum).getFullYear() }}</span>
                                </v-col>
                              </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text style="height:150px;">
                              <v-row align="center" justify="center">
                                <v-col cols="12">
                                  <v-menu
                                    v-model="datepicker"
                                    :close-on-content-click="false"
                                    :nudge-right="30"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                    max-width="290"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn block outlined x-large v-bind="attrs" v-on="on">
                                        <span class="text--primary text-h1">{{
                                          new Date(editableDataset.Datum).toLocaleDateString("de-DE", {
                                            day: "2-digit",
                                          }) + "."
                                        }}</span>
                                      </v-btn>
                                    </template>
                                    <v-date-picker
                                      v-model="editableDataset.Datum"
                                      @input="datepicker = false"
                                      locale="de"
                                      first-day-of-week="1"
                                      full-width
                                    ></v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                              <v-row dense align="center" justify="center">
                                <v-col cols="auto"
                                  ><span class="text--primary text-h5">{{
                                    new Date(editableDataset.Datum).toLocaleDateString("de-DE", {
                                      weekday: "long",
                                    })
                                  }}</span></v-col
                                >
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row dense v-else>
                        <v-col cols="auto" class="mx-auto font-weight-bold">
                          <v-card shaped width="225">
                            <v-card-title style="height:64px;">
                              <v-row dense align="center" justify="center">
                                <v-col cols="auto">Datum(*)</v-col>
                              </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text style="height:150px;">
                              <v-row dense align="center" justify="center" class="fill-height">
                                <v-col cols="12">
                                  <v-menu
                                    v-model="datepicker"
                                    :close-on-content-click="false"
                                    :nudge-right="30"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                    max-width="290"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn outlined block x-large color="green lighten-1" dark v-bind="attrs" v-on="on">
                                        <v-icon x-large>mdi-calendar-plus</v-icon>
                                      </v-btn>
                                    </template>
                                    <v-date-picker
                                      v-model="editableDataset.Datum"
                                      @input="datepicker = false"
                                      locale="de"
                                      first-day-of-week="1"
                                      full-width
                                    ></v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="12" xl="7" lg="7" md="6" sm="6">
                      <v-row dense>
                        <v-col cols="12" xl="7" lg="9" md="12" sm="12">
                          <span class="font-weight-bold">Kundenname(*)</span>
                          <v-text-field v-model="editableClient.Name" hide-details dense outlined clearable required placeholder="Kundenname">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" xl="7" lg="9" md="12" sm="12">
                          <span class="font-weight-bold">Telefon</span>
                          <v-text-field v-model="editableClient.Telefon" hide-details dense outlined clearable required placeholder="Telefon">
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col cols="12" xl="7" lg="9" md="12" sm="12">
                          <span class="font-weight-bold">Email</span>
                          <v-text-field v-model="editableClient.Email" hide-details dense outlined clearable required placeholder="Email">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <!-- Terminartikel -->
          <v-row dense class="align-center justify-center pb-5">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense align="center">
                    <v-col cols="auto">
                      <v-toolbar-title>
                        Artikel
                      </v-toolbar-title>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" v-if="editableArticle.length === 0">
                      <v-row dense align="center" justify="center">
                        <v-col cols="12" xl="auto" lg="auto" md="auto" sm="12">
                          <v-btn block outlined color="green lighten-2" @click="openSelectArticle">
                            <v-icon left>mdi-plus</v-icon>
                            <span>Artikel auswählen</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" v-for="item in editableArticle" :key="item.Artikel_ID">
                      <v-card tile outlined>
                        <v-card-text class="subtitle-2 text--primary">
                          <v-row dense align="center">
                            <v-col cols="auto">
                              <div class="font-weight-bold text-h5">
                                <span>{{ item.Aktion }}</span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row dense align="center">
                            <v-col cols="9" md="4" sm="8" xl="4" lg="4">
                              <span class="font-weight-bold">Artikelnummer: </span>
                              <span>{{ item.Artikelnummer }}</span>
                            </v-col>
                            <v-col cols="8" md="4" sm="8" xl="4" lg="4">
                              <span class="font-weight-bold">Größe: </span>
                              <span>{{ item.Größe }}</span>
                            </v-col>
                            <v-col cols="8" md="4" sm="8" xl="4" lg="4">
                              <span class="font-weight-bold">Preis: </span>
                              <span>{{ item.Preis + " €" }}</span>
                            </v-col>
                            <v-col cols="6" md="6" sm="6" xl="auto" lg="auto">
                              <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon outlined v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information-variant</v-icon>
                                  </v-btn>
                                </template>
                                <v-card width="575">
                                  <v-card-title><span>Artikelinformationen:</span> </v-card-title>
                                  <v-divider></v-divider>
                                  <v-card-text class="text--primary">
                                    <v-row dense align="center" justify="center">
                                      <v-col cols="12" v-for="item in editableArticle" :key="item.Artikel_ID">
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Artikelname
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Name">
                                              {{ item.Name }}
                                            </span>
                                          </v-col>
                                        </v-row>

                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Farbe(n)
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Farben">
                                              {{ item.Farben }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Stoff(e)
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Stoffe">
                                              {{ item.Stoffe }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Schnitt
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Schnitt">
                                              {{ item.Schnitt }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Besonderheiten
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Besonderheiten">
                                              {{ item.Besonderheiten }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            VK brutto
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.VK_brutto">
                                              {{ item.VK_brutto + " €" }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Leihpreis
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Leihpreis">
                                              {{ item.Leihpreis + " €" }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" v-if="editableArticle.length > 0 && item.neu === true">
                              <v-btn text outlined rounded color="red lighten-2" @click="removeArticle(item.Artikel_ID)"
                                ><v-icon>mdi-delete-outline</v-icon></v-btn
                              >
                            </v-col>
                            <v-col cols="auto" v-if="editableArticle.length > 0 && item.neu === false">
                              <v-menu offset-y :close-on-content-click="true">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn outlined rounded color="red lighten-2" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-delete-alert-outline</v-icon>
                                  </v-btn>
                                </template>
                                <v-card width="225">
                                  <v-card-title class="py-1"><span>Artikel entfernen?</span> </v-card-title>
                                  <v-divider></v-divider>
                                  <v-card-text class="text--primary">
                                    <v-row dense align="center" justify="center">
                                      <v-col cols="auto">
                                        <v-btn text outlined rounded color="red lighten-2">Nein </v-btn>
                                      </v-col>
                                      <v-spacer></v-spacer>
                                      <v-col cols="auto">
                                        <v-btn dark color="green lighten-2" rounded @click="removeArticleFromDataset(item)">Ja </v-btn></v-col
                                      >
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <!-- Terminaccessoires -->
          <v-row dense class="align-center justify-center pb-5">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense align="center">
                    <v-col cols="auto">
                      <v-toolbar-title>
                        Accessoires
                      </v-toolbar-title>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" v-for="item in editableAccessoires" :key="item.Artikel_ID">
                      <v-card tile outlined>
                        <v-card-text class="subtitle-2 text--primary">
                          <v-row dense align="center">
                            <v-col cols="auto">
                              <div class="font-weight-bold text-h5">
                                <span>{{ item.Aktion }}</span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row dense align="center">
                            <v-col cols="9" md="4" sm="8" xl="4" lg="4">
                              <span class="font-weight-bold">Artikelnummer: </span>
                              <span>{{ item.Artikelnummer }}</span>
                            </v-col>
                            <v-col cols="8" md="4" sm="8" xl="4" lg="4">
                              <span class="font-weight-bold">Größe: </span>
                              <span>{{ item.Größe }}</span>
                            </v-col>
                            <v-col cols="8" md="4" sm="8" xl="4" lg="4">
                              <span class="font-weight-bold">Preis: </span>
                              <span>{{ item.Preis + " €" }}</span>
                            </v-col>
                            <v-col cols="6" md="6" sm="6" xl="auto" lg="auto">
                              <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn icon outlined v-bind="attrs" v-on="on">
                                    <v-icon>mdi-information-variant</v-icon>
                                  </v-btn>
                                </template>
                                <v-card width="575">
                                  <v-card-title><span>Artikelinformationen:</span> </v-card-title>
                                  <v-divider></v-divider>
                                  <v-card-text class="text--primary">
                                    <v-row dense align="center" justify="center">
                                      <v-col cols="12">
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Artikelname
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Name">
                                              {{ item.Name }}
                                            </span>
                                          </v-col>
                                        </v-row>

                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Farbe(n)
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Farben">
                                              {{ item.Farben }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Stoff(e)
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Stoffe">
                                              {{ item.Stoffe }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Schnitt
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Schnitt">
                                              {{ item.Schnitt }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Besonderheiten
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Besonderheiten">
                                              {{ item.Besonderheiten }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            VK brutto
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.VK_brutto">
                                              {{ item.VK_brutto + " €" }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                        <v-row dense>
                                          <v-col cols="5" class="font-weight-bold">
                                            Leihpreis
                                          </v-col>
                                          <v-col cols="7">
                                            <span v-if="item.Leihpreis">
                                              {{ item.Leihpreis + " €" }}
                                            </span>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" v-if="editableAccessoires.length > 0 && item.neu === true">
                              <v-btn text outlined rounded color="red lighten-2" @click="removeAccessoire(item.Artikel_ID)"
                                ><v-icon>mdi-delete-outline</v-icon></v-btn
                              >
                            </v-col>
                            <v-col cols="auto" v-if="editableAccessoires.length > 0 && item.neu === false">
                              <v-menu offset-y :close-on-content-click="true">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn outlined rounded color="red lighten-2" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-delete-alert-outline</v-icon>
                                  </v-btn>
                                </template>
                                <v-card width="225">
                                  <v-card-title class="py-1"><span>Accessoire entfernen?</span> </v-card-title>
                                  <v-divider></v-divider>
                                  <v-card-text class="text--primary">
                                    <v-row dense align="center" justify="center">
                                      <v-col cols="auto">
                                        <v-btn text outlined rounded color="red lighten-2">Nein </v-btn>
                                      </v-col>
                                      <v-spacer></v-spacer>
                                      <v-col cols="auto">
                                        <v-btn dark color="green lighten-2" rounded @click="removeAccessoireFromDataset(item)">Ja </v-btn></v-col
                                      >
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-row dense align="center" justify="center">
                        <v-col cols="12" xl="auto" lg="auto" md="auto" sm="12">
                          <v-btn block outlined color="green lighten-2" @click="openSelectAccessoire">
                            <v-icon left>mdi-plus</v-icon>
                            <span>Accessoire auswählen</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
          <!-- Terminnotizen -->
          <v-row dense class="align-center justify-center">
            <v-card tile outlined class="flex-grow-1">
              <v-col cols="12">
                <v-card-title class="px-2">
                  <v-row dense align="center">
                    <v-col cols="auto">
                      <v-toolbar-title>
                        Anmerkungen / Notizen
                      </v-toolbar-title>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                      <v-btn outlined color="green lighten-2" @click="openNote"
                        ><v-icon left>mdi-plus</v-icon><span class="d-none d-lg-flex">neue&nbsp;</span> <span>Notiz</span></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider inset></v-divider>
                <v-card-text class="px-2 subtitle-2">
                  <v-row dense align="center">
                    <v-col cols="12" v-if="editableNotes.length === 0">
                      <v-card tile outlined>
                        <v-card-text class="text--primary">
                          keine Anmerkungen / Notizen vorhanden
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" v-for="item in editableNotes" :key="item.temp_id">
                      <v-card tile outlined>
                        <v-card-text>
                          <v-row dense align="center">
                            <v-col cols="auto" class="flex-grow-1 text--primary subtitle-2">
                              {{ item.Notiz }}
                            </v-col>
                            <v-col cols="auto" v-if="editableNotes.length > 0 && item.neu === true">
                              <v-btn text outlined rounded color="red lighten-2" @click="removeNote(item.temp_id)"
                                ><v-icon>mdi-delete-outline</v-icon></v-btn
                              >
                            </v-col>
                            <v-col cols="auto" v-if="editableNotes.length > 0 && item.neu === false">
                              <v-menu offset-y :close-on-content-click="true">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn outlined rounded color="red lighten-2" v-bind="attrs" v-on="on">
                                    <v-icon>mdi-delete-alert-outline</v-icon>
                                  </v-btn>
                                </template>
                                <v-card width="225">
                                  <v-card-title class="py-1"><span>Notiz entfernen?</span> </v-card-title>
                                  <v-divider></v-divider>
                                  <v-card-text class="text--primary">
                                    <v-row dense align="center" justify="center">
                                      <v-col cols="auto">
                                        <v-btn text outlined rounded color="red lighten-2">Nein </v-btn>
                                      </v-col>
                                      <v-spacer></v-spacer>
                                      <v-col cols="auto">
                                        <v-btn dark color="green lighten-2" rounded @click="removeNoteFromDataset(item.Terminnotiz_ID)"
                                          >Ja
                                        </v-btn></v-col
                                      >
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-row>
        </v-col>
      </v-container>
    </v-layout>

    <SelectArticle ref="SelectArticle" @addArticle="addArticle" :initialDataset="tempArticle" :usedArticles="selectedIDs" />
    <SelectAccessoire ref="SelectAccessoire" @addAccessoire="addAccessoire" :initialDataset="tempAccessoire" :usedArticles="selectedIDs" />
    <Note ref="Note" @addNote="addNote" />

    <LeavePathNotPossibleWrapper ref="leavePathNotPossible">
      <LeavePathNotPossible />
    </LeavePathNotPossibleWrapper>

    <LeavePathWrapper ref="leavePath">
      <LeavePathDialog />
    </LeavePathWrapper>
    <DeleteDatasetsDialog ref="DeleteDatasets" @confirmDeleteDatasets="deleteAppointment" @abortDeleteDatasets="abortDeleteDatasets" />
  </v-form>
</template>

<script>
import Note from "./dialogs/Note.vue";
import SelectArticle from "./dialogs/SelectArticle.vue";
import SelectAccessoire from "./dialogs/SelectAccessoire.vue";
import DeleteDatasetsDialog from "../../../components/dialogs/DeleteDatasets.vue";

import LeavePathWrapper from "../../../components/dialogs/LeavePathWrapper.vue";
import LeavePathDialog from "../../../components/dialogs/LeavePathDialog.vue";
import LeavePathNotPossibleWrapper from "../../../components/dialogs/LeavePathNotPossibleWrapper.vue";
import LeavePathNotPossible from "../../../components/dialogs/LeavePathNotPossible.vue";

export default {
  components: {
    Note,
    SelectArticle,
    SelectAccessoire,
    DeleteDatasetsDialog,
    LeavePathWrapper,
    LeavePathDialog,
    LeavePathNotPossibleWrapper,
    LeavePathNotPossible,
  },

  mounted() {
    this.initialize();
  },

  beforeRouteLeave(to, from, next) {
    if (this.saved === true) {
      next();
    } else if (this.saved === false && this.checkDifferences === false && this.checkCriticalDifferences === false) {
      next();
    } else if (this.saved === false && this.checkDifferences === true && this.checkCriticalDifferences === false) {
      this.$refs.leavePath.open().then((result) => {
        if (result === true) {
          next();
        } else if (result === false) {
          next(false);
        }
      });
    } else if (this.saved === false && this.checkCriticalDifferences === true) {
      this.$refs.leavePathNotPossible.open().then((result) => {
        if (result === true) {
          next();
        } else if (result === false) {
          next(false);
        }
      });
    }
  },

  data: () => ({
    Abteilungen: [],
    Terminkategorien: [],
    Artikel: [],
    Artikelnamen: [],
    Bearbeiter: [],
    initialDataset: {},
    initialClient: {},
    editableDataset: {},
    editableClient: {},
    editableArticle: [],
    tempArticle: {},
    editableAccessoires: [],
    tempAccessoire: {},
    editableNotes: [],
    selectedArtikelname: "",
    selectedArtikel: {},
    selectedAccessoires: [{ selectedAccessoire: "" }],
    saved: false,
    datepicker: false,
    date: null,
    artoracc: "",
    selectedArticleAndAccessoires: [],
    criticalChange: false,
    removalHappened: false,
    DeleteDatasets: false,
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    checkDifferences() {
      if (
        this.editableDataset.Abteilung_ID === this.initialDataset.Abteilung_ID &&
        this.editableDataset.Terminkategorie_ID === this.initialDataset.Terminkategorie_ID &&
        this.editableDataset.Datum === this.initialDataset.Datum &&
        this.editableClient.Name === this.initialClient.Name &&
        this.editableClient.Telefon === this.initialClient.Telefon &&
        this.editableClient.Email === this.initialClient.Email &&
        this.criticalChange === false
      ) {
        return false;
      } else return true;
    },

    checkCriticalDifferences() {
      if (this.editableArticle.length > 0 && this.editableArticle[0].neu === false) {
        return false;
      } else return true;
    },

    checkIfOldArticle() {
      if (this.editableArticle.length > 0) {
        const oldArticles = this.editableArticle.filter((item) => item.neu === false);
        if (oldArticles.length === 0) {
          return true;
        } else return false;
      }
    },

    checkIfEmptyArticle() {
      if (this.editableArticle.length === 0) {
        return true;
      } else return false;
    },

    checkCriticalArticle() {
      if (this.checkIfOldArticle === true || this.checkIfEmptyArticle === true) {
        return true;
      } else return false;
    },

    checkIfOldAccessoires() {
      if (this.editableAccessoires.length > 0) {
        const oldAccessoires = this.editableAccessoires.filter((item) => item.neu === false);
        if (oldAccessoires.length === 0) {
          return true;
        } else return false;
      }
    },

    checkIfEmptyAccessoires() {
      if (this.editableAccessoires.length === 0) {
        return true;
      } else return false;
    },

    checkCriticalAccessoires() {
      if (this.checkIfOldAccessoires === true || this.checkIfEmptyAccessoires === true) {
        return true;
      } else return false;
    },

    checkIfOldNotes() {
      if (this.editableNotes.length > 0) {
        const oldNotes = this.editableNotes.filter((item) => item.neu === false);
        if (oldNotes.length === 0) {
          return true;
        } else return false;
      }
    },

    checkIfEmptyNotes() {
      if (this.editableNotes.length === 0) {
        return true;
      } else return false;
    },

    checkCriticalNotes() {
      if (this.checkIfOldNotes === true || this.checkIfEmptyNotes === true) {
        return true;
      } else return false;
    },

    validInsert() {
      if (
        !this.editableDataset.Bearbeiter_ID ||
        !this.editableClient.Name ||
        !this.editableDataset.Terminkategorie_ID ||
        !this.editableDataset.Datum ||
        this.editableArticle.length === 0
      ) {
        return true;
      } else return false;
    },

    dateColor() {
      if (this.editableDataset.Terminkategorie_ID === 1) {
        return "Leih";
      } else if (this.editableDataset.Terminkategorie_ID === 2) {
        return "Kauf";
      } else if (this.editableDataset.Terminkategorie_ID === 3) {
        return "Bestand";
      } else return "";
    },

    selectedArticleID() {
      if (this.editableArticle.length > 0) {
        return this.editableArticle.map((it) => it.Artikel_ID);
      } else return [];
    },

    selectedAccessoireIDs() {
      if (this.editableAccessoires.length > 0) {
        return this.editableAccessoires.map((it) => it.Artikel_ID);
      } else return [];
    },

    selectedIDs() {
      return this.selectedArticleID.concat(this.selectedAccessoireIDs);
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/dataset/editappointment/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.initialDataset = Object.assign({}, this.editableDataset);
      this.initialClient = Object.assign({}, this.editableClient);
      this.initializeArticle();
      this.initializeAccessoires();
      this.initializeNotes();
    },

    async initializeArticle() {
      const response = await fetch(`/api/${this.$route.meta.request}/dataset/editappointment/article/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.editableArticle.forEach((item) => Object.assign(item, { neu: false }));
    },

    async initializeAccessoires() {
      const response = await fetch(`/api/${this.$route.meta.request}/dataset/editappointment/accessoires/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.editableAccessoires.forEach((item) => Object.assign(item, { neu: false }));
    },

    async initializeNotes() {
      const response = await fetch(`/api/${this.$route.meta.request}/dataset/editappointment/notes/${this.$route.params.id}`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.editableNotes.forEach((item) => Object.assign(item, { neu: false }));
    },

    getDateMonthName(date) {
      const monthNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

      const d = new Date(date);
      return monthNames[d.getMonth()];
    },

    openSelectArticle() {
      this.$refs.SelectArticle.open();
    },

    addArticle(article) {
      this.editableArticle.push(article);
      this.criticalChange = true;
    },

    removeArticle(id) {
      var index = this.editableArticle
        .map((x) => {
          return x.Artikel_ID;
        })
        .indexOf(id);

      this.editableArticle.splice(index, 1);
    },

    async removeArticleFromDataset(article) {
      const request = this.$route.meta.request;
      const bearbeiterid = this.editableDataset.Bearbeiter_ID;

      const articleToRemove = {};

      if (Object.keys(articleToRemove).length === 0) {
        Object.assign(articleToRemove, {
          Artikel_ID: article.Artikel_ID,
          Terminartikelposten_ID: article.Terminartikelposten_ID,
          Bearbeiter_ID: bearbeiterid,
          Artikelname_ID: article.Artikelname_ID,
        });
      }

      // Terminartikelposten löschen
      // Artikelevent "von Termin gelöst" hinzufügen
      await fetch(`/api/${request}/article/deleteappointmentarticle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(articleToRemove),
      });

      await fetch(`/api/${request}/article/event/removedfromappointment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(articleToRemove),
      });

      if (article.Aktion === "Verkauf") {
        // wenn der Artikel verkauft wurde

        if (article.Status_ID === 2) {
          // wenn der Artikel immernoch verfügbar ist (d.h. Bestand ist größer 0)

          if (article.Bestellstatus_ID !== 3) {
            // wenn der Artikel noch nicht zum bestellen vorgemerkt wurde
            // Bestand um 1 erhöhen
            // Artikel als "verfügbar" markieren

            await fetch(`/api/${request}/article/stockamount/erhoehen`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToRemove),
            });
          } else if (article.Bestellstatus_ID === 3) {
            // wenn der Artikel zum Bestellen vorgemerkt ist

            if (article.manuell_vorgemerkt === 1) {
              // wenn die Vormerkung manuell erstellt wurde
              // nichts an der Bestellvormerkung ändern
            } else if (article.manuell_vorgemerkt === 0) {
              // wenn die Vormerkung automatisch erstellt wurde
              // zu bestellende Menge um 1 reduzieren

              await fetch(`/api/${request}/article/updateorderproposal/zubestellendemenge/reduzieren`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(articleToRemove),
              });
              if (article.zu_bestellende_Menge - 1 === 0) {
                // wenn die zu bestellende Menge 0 erreicht
                // Bestellvormerkung entfernen
                // Artikel Bestellstatus als "bestellbar" markieren

                await fetch(`/api/${request}/article/deleteorderproposal`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToRemove),
                });
                await fetch(`/api/${request}/article/setorderstatus/bestellbar`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToRemove),
                });
              }
            }
          }
        } else if (article.Status_ID === 3) {
          // wenn der Artikel verkauft ist

          if (article.Bestellstatus_ID !== 3) {
            // wenn der Artikel noch nicht zum bestellen vorgemerkt wurde
            // Bestand um 1 erhöhen
            // Artikel als "verfügbar" markieren

            await fetch(`/api/${request}/article/stockamount/erhoehen`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToRemove),
            });
            await fetch(`/api/${request}/article/setstatus/verfuegbar`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToRemove),
            });
            if (article.Artikelname_ID !== null) {
              await fetch(`/api/database/articles/dataset/name/unavailable`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(articleToRemove),
              });
            }
          } else if (article.Bestellstatus_ID === 3) {
            // wenn der Artikel zum Bestellen vorgemerkt ist

            if (article.manuell_vorgemerkt === 1) {
              // wenn die Vormerkung manuell erstellt wurde
              // nichts an der Bestellvormerkung ändern
            } else if (article.manuell_vorgemerkt === 0) {
              // wenn die Vormerkung automatisch erstellt wurde
              // zu bestellende Menge um 1 reduzieren
              await fetch(`/api/${request}/article/updateorderproposal/zubestellendemenge/reduzieren`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(articleToRemove),
              });

              if (article.zu_bestellende_Menge - 1 === 0) {
                // wenn die zu bestellende Menge 0 erreicht
                // Bestellvormerkung entfernen
                // Artikel Bestellstatus als "bestellbar" markieren
                await fetch(`/api/${request}/article/deleteorderproposal`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToRemove),
                });
                await fetch(`/api/${request}/article/setorderstatus/bestellbar`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToRemove),
                });
              }
            }
          }
        }
      } else if (article.Aktion === "Verleih") {
        // wenn der Artikel verliehen wurde
        // Anzahl verliehen um 1 reduzieren
        await fetch(`/api/${request}/article/amountrent/reduzieren`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(articleToRemove),
        });
      }

      this.$root.actionSnackbar.show({
        dataset: "Artikel",
        action: "von Termin entfernt",
      });

      this.removalHappened = true;
      this.initializeArticle();

      setTimeout(() => {
        this.$root.mainmenu.reinitialize();
      }, 500);
    },

    openSelectAccessoire() {
      this.$refs.SelectAccessoire.open();
    },

    addAccessoire(accessoire) {
      this.editableAccessoires.push(accessoire);
      this.criticalChange = true;
    },

    removeAccessoire(id) {
      var index = this.editableAccessoires
        .map((x) => {
          return x.Artikel_ID;
        })
        .indexOf(id);

      this.editableAccessoires.splice(index, 1);
    },

    async removeAccessoireFromDataset(article) {
      const request = this.$route.meta.request;
      const bearbeiterid = this.editableDataset.Bearbeiter_ID;

      const articleToRemove = {};

      if (Object.keys(articleToRemove).length === 0) {
        Object.assign(articleToRemove, {
          Artikel_ID: article.Artikel_ID,
          Terminartikelposten_ID: article.Terminartikelposten_ID,
          Bearbeiter_ID: bearbeiterid,
          Artikelname_ID: article.Artikelname_ID,
        });
      }

      // Terminartikelposten löschen
      // Artikelevent "von Termin gelöst" hinzufügen
      await fetch(`/api/${request}/article/deleteappointmentarticle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(articleToRemove),
      });

      await fetch(`/api/${request}/article/event/removedfromappointment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(articleToRemove),
      });

      if (article.Aktion === "Verkauf") {
        // wenn der Artikel verkauft wurde

        if (article.Status_ID === 2) {
          // wenn der Artikel immernoch verfügbar ist (d.h. Bestand ist größer 0)

          if (article.Bestellstatus_ID !== 3) {
            // wenn der Artikel noch nicht zum bestellen vorgemerkt wurde
            // Bestand um 1 erhöhen
            // Artikel als "verfügbar" markieren

            await fetch(`/api/${request}/article/stockamount/erhoehen`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToRemove),
            });
          } else if (article.Bestellstatus_ID === 3) {
            // wenn der Artikel zum Bestellen vorgemerkt ist

            if (article.manuell_vorgemerkt === 1) {
              // wenn die Vormerkung manuell erstellt wurde
              // nichts an der Bestellvormerkung ändern
            } else if (article.manuell_vorgemerkt === 0) {
              // wenn die Vormerkung automatisch erstellt wurde
              // zu bestellende Menge um 1 reduzieren

              await fetch(`/api/${request}/article/updateorderproposal/zubestellendemenge/reduzieren`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(articleToRemove),
              });
              if (article.zu_bestellende_Menge - 1 === 0) {
                // wenn die zu bestellende Menge 0 erreicht
                // Bestellvormerkung entfernen
                // Artikel Bestellstatus als "bestellbar" markieren

                await fetch(`/api/${request}/article/deleteorderproposal`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToRemove),
                });
                await fetch(`/api/${request}/article/setorderstatus/bestellbar`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToRemove),
                });
              }
            }
          }
        } else if (article.Status_ID === 3) {
          // wenn der Artikel verkauft ist

          if (article.Bestellstatus_ID !== 3) {
            // wenn der Artikel noch nicht zum bestellen vorgemerkt wurde
            // Bestand um 1 erhöhen
            // Artikel als "verfügbar" markieren

            await fetch(`/api/${request}/article/stockamount/erhoehen`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToRemove),
            });
            await fetch(`/api/${request}/article/setstatus/verfuegbar`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToRemove),
            });
            if (article.Artikelname_ID !== null) {
              await fetch(`/api/database/articles/dataset/name/unavailable`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(articleToRemove),
              });
            }
          } else if (article.Bestellstatus_ID === 3) {
            // wenn der Artikel zum Bestellen vorgemerkt ist

            if (article.manuell_vorgemerkt === 1) {
              // wenn die Vormerkung manuell erstellt wurde
              // nichts an der Bestellvormerkung ändern
            } else if (article.manuell_vorgemerkt === 0) {
              // wenn die Vormerkung automatisch erstellt wurde
              // zu bestellende Menge um 1 reduzieren
              await fetch(`/api/${request}/article/updateorderproposal/zubestellendemenge/reduzieren`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(articleToRemove),
              });

              if (article.zu_bestellende_Menge - 1 === 0) {
                // wenn die zu bestellende Menge 0 erreicht
                // Bestellvormerkung entfernen
                // Artikel Bestellstatus als "bestellbar" markieren
                await fetch(`/api/${request}/article/deleteorderproposal`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToRemove),
                });
                await fetch(`/api/${request}/article/setorderstatus/bestellbar`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToRemove),
                });
              }
            }
          }
        }
      } else if (article.Aktion === "Verleih") {
        // wenn der Artikel verliehen wurde
        // Anzahl verliehen um 1 reduzieren
        await fetch(`/api/${request}/article/amountrent/reduzieren`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(articleToRemove),
        });
      }

      this.$root.actionSnackbar.show({
        dataset: "Artikel",
        action: "von Termin entfernt",
      });

      this.removalHappened = true;
      this.initializeAccessoires();

      setTimeout(() => {
        this.$root.mainmenu.reinitialize();
      }, 500);
    },

    openNote() {
      const noteid = this.editableNotes.length;
      this.$refs.Note.open(noteid);
    },

    addNote(note) {
      this.editableNotes.push(note);
      this.criticalChange = true;
    },

    removeNote(id) {
      var index = this.editableNotes
        .map((x) => {
          return x.temp_id;
        })
        .indexOf(id);

      this.editableNotes.splice(index, 1);
    },

    async removeNoteFromDataset(id) {
      const noteToDelete = {};

      if (Object.keys(noteToDelete).length === 0) {
        Object.assign(noteToDelete, {
          Terminnotiz_ID: id,
        });
      }

      await fetch(`/api/${this.$route.meta.request}/note/deletenote`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(noteToDelete),
      });

      this.removalHappened = true;
      this.initializeNotes();
    },

    async kundenSpeichern() {
      const clientToInsert = {};

      if (Object.keys(clientToInsert).length === 0) {
        Object.assign(clientToInsert, {
          Kunden_ID: this.editableClient.Kunden_ID,
          Name: this.editableClient.Name,
          Telefon: this.editableClient.Telefon,
          Email: this.editableClient.Email,
        });
      }

      await fetch(`/api/${this.$route.meta.request}/client/updateclient`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(clientToInsert),
      });

      this.terminSpeichern(clientToInsert.Kunden_ID);
    },

    async terminSpeichern(clientid) {
      const datasetToInsert = {};

      if (Object.keys(datasetToInsert).length === 0) {
        Object.assign(datasetToInsert, {
          Termin_ID: this.editableDataset.Termin_ID,
          Abteilung_ID: this.editableDataset.Abteilung_ID,
          Terminkategorie_ID: this.editableDataset.Terminkategorie_ID,
          Datum: this.editableDataset.Datum,
          Bearbeiter_ID: this.editableDataset.Bearbeiter_ID,
          ist_abgesagt: 0,
        });
      }

      await fetch(`/api/${this.$route.meta.request}/dataset/updateappointment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToInsert),
      });

      const datasetEventToInsert = {};

      if (Object.keys(datasetEventToInsert).length === 0) {
        Object.assign(datasetEventToInsert, {
          Vorgang_ID: 2,
          Termin_ID: this.editableDataset.Termin_ID,
          Bearbeiter_ID: this.editableDataset.Bearbeiter_ID,
        });
      }

      await fetch(`/api/${this.$route.meta.request}/event/createevent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetEventToInsert),
      });

      this.terminArtikelSpeichern(datasetToInsert.Termin_ID, clientid, datasetToInsert.Bearbeiter_ID);
      this.terminAccessoiresSpeichern(datasetToInsert.Termin_ID, clientid, datasetToInsert.Bearbeiter_ID);
      this.notizenSpeichern(datasetToInsert.Termin_ID);

      this.$root.actionSnackbar.show({
        dataset: "Termin",
        action: "bearbeitet",
      });

      this.saved = true;
      this.$router.go(-1);
    },

    async terminArtikelSpeichern(newappointmentid, newclientid, bearbeiterid) {
      const multipleArticlesAdd = this.editableArticle;
      const request = this.$route.meta.request;

      multipleArticlesAdd.forEach(async function(article) {
        const articleToAdd = {};

        if (Object.keys(articleToAdd).length === 0) {
          Object.assign(articleToAdd, {
            Termin_ID: newappointmentid,
            Artikel_ID: article.Artikel_ID,
            Artikelname_ID: article.Artikelname_ID,
            Preis: article.Preis,
            Bearbeiter_ID: bearbeiterid,
          });
        }

        if (article.neu === true) {
          // wenn es ein neuer Artikel ist

          if (article.Aktion === "Verkauf") {
            // wenn der Artikel verkauft wird
            // Terminartikelposten speichern
            // Artikelevent "Verkauft" hinzufügen
            await fetch(`/api/${request}/article/insertappointmentarticlesold`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });
            await fetch(`/api/${request}/article/addevent/verkauft`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });

            if (article.Status_ID === 2) {
              // wenn der Artikel verfügbar (d.h. Bestand ist größer 0)

              if (article.Bestellstatus_ID === 1) {
                // wenn der Artikel nicht bestellbar ist
                // Bestand um 1 reduzieren

                await fetch(`/api/${request}/article/stockamount/reduzieren`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });

                if (article.Bestand - 1 === 0) {
                  // wenn der Bestand durch den Verkauf 0 erreicht
                  // Artikel als verkauft markieren
                  await fetch(`/api/${request}/article/setstatus/verkauft`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(articleToAdd),
                  });
                  if (article.Artikelname_ID !== null) {
                    await fetch(`/api/database/articles/dataset/name/available`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                }
              } else if (article.Bestellstatus_ID === 2) {
                // wenn der Artikel bestellbar ist
                // Bestand um 1 reduzieren
                await fetch(`/api/${request}/article/stockamount/reduzieren`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });

                if (article.ist_bestandsgeführt === 0) {
                  // wenn der Artikel nicht bestandsgeführt ist

                  if (article.Bestand - 1 === 0) {
                    // wenn der Bestand durch den Verkauf 0 erreicht
                    // Artikel als verkauft markieren
                    await fetch(`/api/${request}/article/setstatus/verkauft`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                    if (article.Artikelname_ID !== null) {
                      await fetch(`/api/database/articles/dataset/name/available`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(articleToAdd),
                      });
                    }
                  }
                } else if (article.ist_bestandsgeführt === 1) {
                  // wenn der Artikel bestandsgeführt ist

                  if (article.Bestand - 1 < article.Mindestbestand) {
                    // wenn der Bestand den Mindestbestand durch den Verkauf unterschreitet
                    // automatisch Bestellvormerkung erstellen (nicht verpflichtend)
                    // Artikel Bestellstatus als "vorgemerkt" markieren
                    await fetch(`/api/${request}/article/addorderproposal`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });

                    await fetch(`/api/${request}/article/setorderstatus/vorgemerkt`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                  if (article.Bestand - 1 === 0) {
                    // wenn der Bestand durch den Verkauf 0 erreicht
                    // Artikel als verkauft markieren
                    await fetch(`/api/${request}/article/setstatus/verkauft`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                }
              } else if (article.Bestellstatus_ID === 3) {
                // wenn der Artikel bereits zum Bestellen vorgemerkt ist
                // Bestand um 1 reduzieren
                await fetch(`/api/${request}/article/stockamount/reduzieren`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });

                if (article.ist_bestandsgeführt === 0) {
                  // wenn der Artikel nicht bestandsgeführt ist

                  if (article.Bestand - 1 === 0) {
                    // wenn der Bestand durch den Verkauf 0 erreicht
                    // Artikel als verkauft markieren
                    await fetch(`/api/${request}/article/setstatus/verkauft`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                } else if (article.ist_bestandsgeführt === 1) {
                  // wenn der Artikel bestandsgeführt ist

                  if (article.Bestand - 1 < article.Mindestbestand) {
                    // wenn der Bestand den Mindestbestand durch den Verkauf unterschreitet
                    // automatisch die zu bestellende Menge in der bestehenden Bestellvormerkung um 1 erhöhen
                    await fetch(`/api/${request}/article/updateorderproposal/zubestellendemenge/erhoehen`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                  if (article.Bestand - 1 === 0) {
                    // wenn der Bestand durch den Verkauf 0 erreicht
                    // Artikel als verkauft markieren
                    await fetch(`/api/${request}/article/setstatus/verkauft`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                }
              }
            } else if (article.Status_ID === 3) {
              // wenn der Artikel bereits verkauft ist (d.h. Bestand ist bereits 0 und kann nicht weiter reduziert werden)

              if (article.Bestellstatus_ID === 1) {
                // wenn der Artikel nicht bestellbar ist
                // dann darf der Artikel gar nicht erst ausgewählt werden
              } else if (article.Bestellstatus_ID === 2) {
                // wenn der Artikel bestellbar ist
                // automatisch Bestellvormerkung erstellen mit zu bestellender Menge 1 und diese verpflichtend machen
                // Artikel Bestellstatus als "vorgemerkt" markieren
                await fetch(`/api/${request}/article/addorderproposal`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });
                await fetch(`/api/${request}/article/setorderstatus/vorgemerkt`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });
              } else if (article.Bestellstatus_ID === 3) {
                // wenn der Artikel bereits zum Bestellen vorgemerkt ist
                // automatisch die zu bestellende Menge in der bestehenden Bestellvormerkung um 1 erhöhen
                await fetch(`/api/${request}/article/updateorderproposal/zubestellendemenge/erhoehen`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });
              }
            }
          } else if (article.Aktion === "Verleih") {
            await fetch(`/api/${request}/article/insertappointmentarticlerent`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });
            await fetch(`/api/${request}/article/amountrent/erhoehen`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });
            await fetch(`/api/${request}/article/addevent/verliehen`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });
          }
        }
      });
    },

    async terminAccessoiresSpeichern(newappointmentid, newclientid, bearbeiterid) {
      const multipleArticlesAdd = this.editableAccessoires;
      const request = this.$route.meta.request;

      multipleArticlesAdd.forEach(async function(article) {
        const articleToAdd = {};

        if (Object.keys(articleToAdd).length === 0) {
          Object.assign(articleToAdd, {
            Termin_ID: newappointmentid,
            Artikel_ID: article.Artikel_ID,
            Artikelname_ID: article.Artikelname_ID,
            Preis: article.Preis,
            Bearbeiter_ID: bearbeiterid,
          });
        }

        if (article.neu === true) {
          // wenn es ein neuer Artikel ist

          if (article.Aktion === "Verkauf") {
            // wenn der Artikel verkauft wird
            // Terminartikelposten speichern
            // Artikelevent "Verkauft" hinzufügen
            await fetch(`/api/${request}/article/insertappointmentaccessoiresold`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });
            await fetch(`/api/${request}/article/addevent/verkauft`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });

            if (article.Status_ID === 2) {
              // wenn der Artikel verfügbar (d.h. Bestand ist größer 0)

              if (article.Bestellstatus_ID === 1) {
                // wenn der Artikel nicht bestellbar ist
                // Bestand um 1 reduzieren

                await fetch(`/api/${request}/article/stockamount/reduzieren`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });

                if (article.Bestand - 1 === 0) {
                  // wenn der Bestand durch den Verkauf 0 erreicht
                  // Artikel als verkauft markieren
                  await fetch(`/api/${request}/article/setstatus/verkauft`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(articleToAdd),
                  });
                  if (article.Artikelname_ID !== null) {
                    await fetch(`/api/database/articles/dataset/name/available`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                }
              } else if (article.Bestellstatus_ID === 2) {
                // wenn der Artikel bestellbar ist
                // Bestand um 1 reduzieren
                await fetch(`/api/${request}/article/stockamount/reduzieren`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });

                if (article.ist_bestandsgeführt === 0) {
                  // wenn der Artikel nicht bestandsgeführt ist

                  if (article.Bestand - 1 === 0) {
                    // wenn der Bestand durch den Verkauf 0 erreicht
                    // Artikel als verkauft markieren
                    await fetch(`/api/${request}/article/setstatus/verkauft`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                    if (article.Artikelname_ID !== null) {
                      await fetch(`/api/database/articles/dataset/name/available`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify(articleToAdd),
                      });
                    }
                  }
                } else if (article.ist_bestandsgeführt === 1) {
                  // wenn der Artikel bestandsgeführt ist

                  if (article.Bestand - 1 < article.Mindestbestand) {
                    // wenn der Bestand den Mindestbestand durch den Verkauf unterschreitet
                    // automatisch Bestellvormerkung erstellen (nicht verpflichtend)
                    // Artikel Bestellstatus als "vorgemerkt" markieren
                    await fetch(`/api/${request}/article/addorderproposal`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });

                    await fetch(`/api/${request}/article/setorderstatus/vorgemerkt`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                  if (article.Bestand - 1 === 0) {
                    // wenn der Bestand durch den Verkauf 0 erreicht
                    // Artikel als verkauft markieren
                    await fetch(`/api/${request}/article/setstatus/verkauft`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                }
              } else if (article.Bestellstatus_ID === 3) {
                // wenn der Artikel bereits zum Bestellen vorgemerkt ist
                // Bestand um 1 reduzieren
                await fetch(`/api/${request}/article/stockamount/reduzieren`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });

                if (article.ist_bestandsgeführt === 0) {
                  // wenn der Artikel nicht bestandsgeführt ist

                  if (article.Bestand - 1 === 0) {
                    // wenn der Bestand durch den Verkauf 0 erreicht
                    // Artikel als verkauft markieren
                    await fetch(`/api/${request}/article/setstatus/verkauft`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                } else if (article.ist_bestandsgeführt === 1) {
                  // wenn der Artikel bestandsgeführt ist

                  if (article.Bestand - 1 < article.Mindestbestand) {
                    // wenn der Bestand den Mindestbestand durch den Verkauf unterschreitet
                    // automatisch die zu bestellende Menge in der bestehenden Bestellvormerkung um 1 erhöhen
                    await fetch(`/api/${request}/article/updateorderproposal/zubestellendemenge/erhoehen`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                  if (article.Bestand - 1 === 0) {
                    // wenn der Bestand durch den Verkauf 0 erreicht
                    // Artikel als verkauft markieren
                    await fetch(`/api/${request}/article/setstatus/verkauft`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(articleToAdd),
                    });
                  }
                }
              }
            } else if (article.Status_ID === 3) {
              // wenn der Artikel bereits verkauft ist (d.h. Bestand ist bereits 0 und kann nicht weiter reduziert werden)

              if (article.Bestellstatus_ID === 1) {
                // wenn der Artikel nicht bestellbar ist
                // dann darf der Artikel gar nicht erst ausgewählt werden
              } else if (article.Bestellstatus_ID === 2) {
                // wenn der Artikel bestellbar ist
                // automatisch Bestellvormerkung erstellen mit zu bestellender Menge 1 und diese verpflichtend machen
                // Artikel Bestellstatus als "vorgemerkt" markieren
                await fetch(`/api/${request}/article/addorderproposal`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });
                await fetch(`/api/${request}/article/setorderstatus/vorgemerkt`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });
              } else if (article.Bestellstatus_ID === 3) {
                // wenn der Artikel bereits zum Bestellen vorgemerkt ist
                // automatisch die zu bestellende Menge in der bestehenden Bestellvormerkung um 1 erhöhen
                await fetch(`/api/${request}/article/updateorderproposal/zubestellendemenge/erhoehen`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(articleToAdd),
                });
              }
            }
          } else if (article.Aktion === "Verleih") {
            await fetch(`/api/${request}/article/insertappointmentaccessoirerent`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });
            await fetch(`/api/${request}/article/amountrent/erhoehen`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });
            await fetch(`/api/${request}/article/addevent/verliehen`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(articleToAdd),
            });
          }
        }
      });
    },

    async notizenSpeichern(newappointmentid) {
      const multipleNotesAdd = this.editableNotes;
      const request = this.$route.meta.request;

      multipleNotesAdd.forEach(async function(note) {
        if (note.neu === true) {
          const noteToAdd = {};

          if (Object.keys(noteToAdd).length === 0) {
            Object.assign(noteToAdd, {
              Termin_ID: newappointmentid,
              Notiz: note.Notiz,
              neu: note.neu,
            });

            await fetch(`/api/${request}/note/insertnote`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(noteToAdd),
            });
          }
        }
      });

      setTimeout(() => {
        this.$root.mainmenu.reinitialize();
      }, 500);
    },

    async fertig() {
      const datasetEventToInsert = {};

      if (Object.keys(datasetEventToInsert).length === 0) {
        Object.assign(datasetEventToInsert, {
          Vorgang_ID: 2,
          Termin_ID: this.editableDataset.Termin_ID,
          Bearbeiter_ID: this.editableDataset.Bearbeiter_ID,
        });
      }

      await fetch(`/api/${this.$route.meta.request}/event/createevent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetEventToInsert),
      });

      this.$root.actionSnackbar.show({
        dataset: "Termin",
        action: "bearbeitet",
      });
      this.saved = true;
      this.$router.go(-1);
    },

    openDeleteDatasets() {
      this.$refs.DeleteDatasets.open();
    },

    abortDeleteDatasets() {
      this.DeleteDatasets = false;
    },

    async deleteAppointment() {
      const datasetToDelete = {};

      if (Object.keys(datasetToDelete).length === 0) {
        Object.assign(datasetToDelete, {
          Termin_ID: this.editableDataset.Termin_ID,
          Kunden_ID: this.editableClient.Kunden_ID,
        });
      }

      await fetch(`/api/${this.$route.meta.request}/deleteappointment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToDelete),
      });

      await fetch(`/api/${this.$route.meta.request}/deleteclient`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToDelete),
      });

      await fetch(`/api/${this.$route.meta.request}/deleteappointmentevents`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToDelete),
      });

      this.$root.actionSnackbar.show({
        dataset: "Termin",
        action: "gelöscht",
      });
      this.DeleteDatasets = false;
      this.saved = true;
      this.$router.replace({ path: `/wws/appointments/table` });
    },
  },
};
</script>

<style>
.Leih {
  background: #53ff5c !important;
}

.Kauf {
  background: #fcff51 !important;
}

.Bestand {
  background: #d52bff !important;
}
</style>
