<template>
 
    <v-card tile class="pa-3">
      <v-card-title
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title>Nicht möglich!</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>

      <v-card-text class="text--primary py-5 px-3">
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="12"
              ><span>Die Seite kann derzeit nicht verlassen werden!</span>  
            </v-col>
            <v-col cols="12">
              <span>Entweder ist dem Termin kein Artikel zugeordnet oder dieser wurde neu hinzugefügt und noch nicht gespeichert.</span>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            
            <v-col cols="auto">
              <v-btn
                outlined
                color=""
                @click="cancel"
              >
                <span>Okay</span>
              </v-btn>
            </v-col><v-spacer></v-spacer>
            <v-col cols="auto" class="d-none">
              <v-btn color="green lighten-1" @click="agree">
                <v-icon left class="grey--text text--lighten-4"
                  >mdi-check
                </v-icon>
                <span class="grey--text text--lighten-4"
                  >Ja</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  
</template>

<script>
export default {
  name: "dlg-frame", 
  data: () => ({}),
  inject: ["agree", "cancel"],
  methods: {}
};
</script>